<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) How many moles of ammonium bromide,
        <chemical-latex content="NH4Br," />
        would you need to add to a
        <stemble-latex content="$\text{100.0 mL}$" />
        solution of
        <number-value :value="concA" unit="\text{M}" />
        <chemical-latex content="NH3" />
        to obtain a solution with a
        <stemble-latex content="$\text{pOH}$" />
        of
        <number-value :value="pOH" />. Note that the
        <stemble-latex content="$\text{K}_\text{b}$" />
        of ammonia is
        <stemble-latex content="$1.8\times 10^{-5}.$" />
        Assume that the addition of ammonium bromide does not affect the volume of the solution.
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\ce{NH4+}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the pH of the buffer described in <b>part a)</b>
        after
        <stemble-latex content="$\text{4.00 mL}$" />
        of
        <number-value :value="concHBr" unit="\text{M}" />
        <chemical-latex content="HBr" />
        is added to the solution.
      </p>

      <calculation-input
        v-model="inputs.pHb"
        class="mb-5"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Determine the pH of the buffer described in <b>part a)</b>
        after
        <stemble-latex content="$\text{6.00 mL}$" />
        of
        <number-value :value="concHBr" unit="\text{M}" />
        <chemical-latex content="NaOH" />
        is added to the solution.
      </p>

      <calculation-input
        v-model="inputs.pHc"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question307',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        mol: null,
        pHb: null,
        pHc: null,
      },
    };
  },
  computed: {
    pOH() {
      return this.taskState.getValueBySymbol('pOH').content;
    },
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concHBr() {
      return this.taskState.getValueBySymbol('concHBr').content;
    },
  },
};
</script>
